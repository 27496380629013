<template>
  <div class="white pa-4" style="border-radius: 15px">
    <v-btn class="btnclean mb-1" @click="showfilterdata = !showfilterdata">
      Filtrar
    </v-btn>
    <GeneralFilter
      entity="lista"
      :filterEndpoint="$API.products.getProductsProviderFiltered"
      :search="search"
      @emptyFilter="getProductsByProv"
      @filtered="filterHandler"
    >
      <DxDataGrid class="isScrolledByGeneralFilter"
        :data-source="products"
        key-expr="ID_Provider"
        :show-row-lines="showRowLines"
        @exporting="onExporting"
        :paging="{ enabled: false }"  
        style="max-height: 600px"
      >
        <DxScrolling row-rendering-mode="virtual" />
        <DxExport :enabled="true" />

        <!--DxPaging :page-size="10" /-->
        <DxColumn
        v-if="$store.getters['rolesUser/getCustomRole'](129)"
          :width="80"
          cell-template="show-template"
          caption=""
        ></DxColumn>
        <DxColumn data-field="ID_Item" caption="ID"></DxColumn>
        <DxColumn cell-template="show-barcodes" caption="Códigos" :width="200"></DxColumn>
        <template #show-barcodes="{ data }">
          <v-btn class="btn-add" @click="showCodesOfProduct(data.data)">
            Ver códigos alternos
          </v-btn>                      
        </template>
        <DxColumn data-field="ItemName" caption="Nombre"></DxColumn>
        <DxColumn data-field="ItemType" caption="Tipo"></DxColumn>
        <DxColumn data-field="ItemPrice" caption="Precio en $">
          <DxFormat type="fixedPoint" :precision="2"/>
        </DxColumn>
        <DxColumn data-field="AverageCost" caption="Costo promedio">
          <DxFormat type="fixedPoint" :precision="2"/>
        </DxColumn>
        <DxColumn data-field="stock" caption="Disponible"></DxColumn>
        <template #show-template="{ data }">
          <a
            href="#"
            class="mx-1"
            @click="
              $store.dispatch('addWindow', {
                name: 'ManageProduct',
                component: 'ManageProduct',
                unique: true,
                meta: { product: data.data },
              })
            "
          >
            <v-icon color="primary">mdi-eye</v-icon>
          </a>
        </template>
      </DxDataGrid>
    </GeneralFilter>
    <DxButton
      v-if="$store.getters['rolesUser/getCustomRole'](130)"
      :width="300"
      text="Agregar productos a proveedor"
      type="default"
      styling-mode="contained"
      :elementAttr="{ style: 'background-color: #00A178 !important;' }"
      @click="
        $store.dispatch('addWindow', {
          name: 'addProduct',
          component: 'addProduct',
          unique: true,
          meta: { product: providerID },
        })
      "
    />
    <v-dialog v-model="showfilterdata" scrollable :max-width="$vuetify.breakpoint.smAndDown ?'90%':'60%'">
      <v-card class="pa-4">
        <v-card-title>
          <span class="headline">Filtros</span>
        </v-card-title>
        <v-card-text>
          <v-select
            outlined
            dense
            attach
            label="Almacen"
            v-model="filters_selected.warehouse"
            item-text="name"
            item-value="value"
            :items="filterParams.warehouse"
          ></v-select>
          <v-autocomplete
            outlined
            dense
            label="Familia"
            v-model="filters_selected.family"
            :items="families"
            item-text="name"
            attach
            item-value="id"
          ></v-autocomplete>
          <v-autocomplete
            outlined
            dense
            label="Categoría"
            v-model="filters_selected.category"
            :items="filteredCategories"
            item-text="name"
            attach
            item-value="id"
            :disabled="!filters_selected.family"
          ></v-autocomplete>
          <v-autocomplete
            outlined
            dense
            label="Subcategoría"
            v-model="filters_selected.subcategory"
            :items="filteredSubcategories"
            attach
            item-text="name"
            item-value="id"
            :disabled="!filters_selected.category"
          ></v-autocomplete>
          <div style="display: flex; justify-content: space-between">
            <v-btn
              class="btnclean"
              @click="
                showfilterdata = !showfilterdata;
                cleanFilters();
              "
              >Limpiar filtros</v-btn
            >
            <v-btn
              class="btn-add"
              @click="
                showfilterdata = !showfilterdata;
                filterValues();
              "
              >Aplicar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBarCodes" max-width="600">
      <v-card>
        <v-card-title>
          Códigos de barra / Códigos alternos
        </v-card-title>
        <v-card-text>
          <DxDataGrid :data-source="codes">          
            <DxColumn data-field="BarCode" caption="Códigos de barra / código alterno"></DxColumn>
            <DxColumn :width="70" cell-template="check-principal" caption="Principal"></DxColumn>
            <template #check-principal="{data}">     
              <div>
                <center><v-checkbox readonly v-model="data.data.PrincipalBarCode"></v-checkbox></center>    
              </div>
            </template>
          </DxDataGrid>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn class="btncontinue" @click="showBarCodes = false">
              Continuar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>  
    </v-dialog>

    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </div>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import Alerts from "@/components/Alerts";
import DxButton from "devextreme-vue/button";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxExport,
  DxFormat,
} from "devextreme-vue/data-grid";
import { printToExcel } from "@/helpers/printToexcel";
import { mapState } from "vuex";
export default {
  name: "ProductProvider",
  components: {
    Alerts,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxButton,
    DxExport,
    DxFormat,
    GeneralFilter
  },
  props: ["win", "data"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      form: {},
      tab: null,
      entity: ["PERSONA NATURAL", "SOCIEDAD ANÓNIMA", "CORPORACIÓN"],
      size: ["PEQUEÑO", "MEDIANO", "GRANDE"],
      loading: false,
      requiredRule: [(value) => !!value || "Campo requerido."],
      emailRules: [
        (v) => !!v || "Correo eléctronico requerido",
        (v) => /.+@.+/.test(v) || "El correo eléctronico debe ser valido",
      ],
      valid: false,
      providerID: null,
      products: [],
      showRowLines: true,
      filterWarehouses: {},
      showfilterdata: false,
      AllData: [],
      filterParams: {
        warehouse: [{ name: "Todos", value: "Todos" }],
        family: [{ name: "Todos", value: "Todos" }],
      },
      warehouses: {},
      families: {},
      categories: {},
      subcategories: {},
      filters_selected: {
        warehouse: "Todos",
        family: null,
        category: null,
        subcategory: null,
      },
      search: {
        filter: null,
        idProvider: null,
      },
      productsByIdProvider: null,
      codes: [],
      showBarCodes: false,
    };
  },

  computed: {
    filteredCategories() {
      return this.filters_selected.family
        ? this.categories.filter(
            (category) => category.id_family == this.filters_selected.family
          )
        : [];
    },
    filteredSubcategories() {
      return this.filters_selected.category
        ? this.subcategories.filter(
            (subcategory) =>
              subcategory.id_cat == this.filters_selected.category
          )
        : [];
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  mounted() {
    /*    console.log("esta es la data", this.data);
    console.log("esta es la win", this.win); */
    this.$store.dispatch("rolesUser/getAllRoles");
    this.getWarehouse();
    this.getFamilies();
    this.getCategories();
    this.getSubs();
    this.providerID = this.data.ID_Provider;
    console.log("4wte es el prov id", this.providerID);
    this.getProductsByProv(this.data.ID_Provider);
    this.form.idbusiness =
      JSON.parse(localStorage.getItem("user")).businessid || "";
    this.form.idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.form.iduserinsert = JSON.parse(localStorage.getItem("user")).id || "";
  },
  methods: {
    showCodesOfProduct(data) {
      this.codes = []
      this.$API.inventoryitemsbarcodes.getBarCodesByProduct({ ID_Item: data.ID_Item}).then(response => {
        this.codes = response
        this.showBarCodes = true;
      })
    },
    onExporting(e) {
      printToExcel(e, "Productos de proveedor");
    },
    closeAlert() {
      this.alert.show = false;
      if (this.alert.options == "providerCreated") {
        this.$store
          .dispatch("deleteWindowByUuid", { uuid: this.win.uuid })
          .then(() => {
            this.$store.dispatch("addWindow", {
              name: "ProvidersList",
              component: "ProvidersList",
              unique: true,
            });
            this.$store.dispatch("reloadData", "providers");
          });
      }
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then((response) => {
        this.families = response;
        // console.log("response famili", response);
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategories().then((response) => {
        this.categories = response;
      });
    },
    getSubs() {
      this.$API.itemtypes.getSubs().then((response) => {
        this.subcategories = response;
      });
    },
    getWarehouse() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.filterWarehouses.branch = idbranch;
      this.filterWarehouses.business = idbusiness;
      this.$API.warehouse
        .getWarehouses(this.filterWarehouses)
        .then((response) => {
          this.warehouses = response;
        });
    },

    getProductsByProv(idprov) {

      if(idprov != null || idprov != undefined){
        this.productsByIdProvider = idprov;
      }

      let user = JSON.parse(localStorage.getItem("user"));
      let data = {
        id: idprov || this.productsByIdProvider,
        branch: user.branch,
        business: user.businessid,
      };

      this.search.idProvider = idprov || this.productsByIdProvider

      this.$API.products.getProductsProviderHistory(data).then((response) => {
        this.products = response;
        this.AllData = response;
        //console.log(this.products);
        this.AllData.forEach((element) => {
          if (
            !this.filterParams.warehouse.some(
              (w) => w.value === element.Warehouse
            )
          ) {
            this.warehouses.forEach((warehouse) => {
              if (warehouse.ID_Warehouse == element.Warehouse) {
                this.filterParams.warehouse.push({
                  name: warehouse.Warehouse,
                  value: warehouse.ID_Warehouse,
                });
              }
            });
          }
        });
      });
    },
    filterValues() {
      this.products = this.AllData;
      if (this.filters_selected.warehouse != "Todos") {
        this.products = this.products.filter(
          (product) => product.Warehouse == this.filters_selected.warehouse
        );
      }
      if (this.filters_selected.family) {
        this.products = this.products.filter(
          (product) => product.family == this.filters_selected.family
        );
      }
      if (this.filters_selected.category) {
        this.products = this.products.filter(
          (product) => product.category == this.filters_selected.category
        );
      }
    },
    cleanFilters() {
      this.filters_selected.warehouse = "Todos";
      this.filters_selected.family = null;
      this.filters_selected.category = null;
      this.filters_selected.subcategory = null;
      this.products = this.AllData;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    acceptAlert() {
      this.alert.show = false;
    },
    addProviderHandler() {
      this.loading = true;
      this.$API.providers
        .addProvider(this.form)
        .then((response) => {
          console.log(response);
          this.loading = false;
          //throw new Error("Error");
          //this.$toasted.success(response.message);

          this.showAlert(
            "success",
            "Ingreso correcto",
            "El proveedor ha sido ingresado correctamente",
            "providerCreated"
          );
          this.form = {};
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error al ingresar proveedor",
            "lo sentimos, ha ocurrido un error al ingresar el proveedor por favor intente nuevamente mas tarde"
          );
        });
    },
    onEditorChange(e) {
      this.form.notes = e.html;
    },
    filterHandler(response) {
      this.products = response;
      this.AllData = response;

      this.AllData.forEach((element) => {
        if (
          !this.filterParams.warehouse.some(
            (w) => w.value === element.Warehouse
          )
        ) {
          this.warehouses.forEach((warehouse) => {
            if (warehouse.ID_Warehouse == element.Warehouse) {
              this.filterParams.warehouse.push({
                name: warehouse.Warehouse,
                value: warehouse.ID_Warehouse,
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
</style>
